import "./style.scss";
import autocomplete from "autocompleter";
import placeUrl from "./place.svg";

export default function autocompleter(input) {
  return autocomplete({
    input: input,
    preventSubmit: true,
    disableAutoSelect: false,
    minLength: 3,
    fetch: function(text, update) {
        text = text.toLowerCase();
        ajaxSource(text.toLowerCase(), update)
    },
    onSelect: function(item) {
        input.dispatchEvent(new CustomEvent('itemSelected', {detail: item}));
    },
    render: function(item, currentValue) {
      const div = document.createElement("div");
      div.classList = 'autocomplete-item';
      
      let html = renderAutocomplete(item);

      div.innerHTML = html;
      return div;
    }
  });
}

function renderAutocomplete (item) {
  var type = '';
  var title = '';
  var description = '';

  if (item.itemType === 'PLACE') {
    type = 'Local';
    title = item.place;
    description = item.address + ' - ' + item.neighborhood + ' - ' + item.city + ' ' + item.state;
  } else if (item.itemType === 'ADDRESS') {
    type = 'Endereço';
    title = item.address;
    description = item.neighborhood + ' - ' + item.city + ' ' + item.state;
  } else if (item.itemType === 'NEIGHBORHOOD') {
    type = 'Bairro';
    title = item.neighborhood;
    description = item.city + ' ' + item.state;
  } else {
    type = 'Município';
    title = item.city;  
    description = item.city + ' ' + item.state;
  }

  switch (item.itemType) {
    case 'PLACE': type = 'Local';break;
    case 'ADDRESS': type = 'Endereço';break;
    case 'NEIGHBORHOOD': type = 'Bairro';break;
    case 'CITY': type = 'Município';break;
  }
  var html = '<div class="autocomplete-suggestion autocomplete-item" data-url="/' + item.slug + '">'
      html += '<img class="icon" src="' + placeUrl + '">';
      html += '<div class="item-detail">';
      html += '<div class="item-title">' + title + ' <span class="item-type">(' + type + ')</span></div>';
      html += '<div class="item-address">' + description + '</div></div></div>';
      
  return html;
}

function ajaxSource (term, response) {
  try { xhr.abort(); } catch(e){}
  let searchHost = ''; // current server
  let request = new XMLHttpRequest();
  request.open('GET', searchHost + '/search?q=' + term, true);
  request.onload = function() {
    if (this.status >= 200 && this.status < 400) {
      response(JSON.parse(this.response));
    } else {
      console.log('Erro ao baixar dados do endereço');
    }
  };

  request.onerror = function() {
    console.log('Erro de conexão com servidor');
  };
  request.send();
}
